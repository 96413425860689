// 维保配置-备件单位配置
<template>
    <div class="page_container height_100">
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>备件单位配置</span>
            </div>
            <!-- mg_top_20 -->
            <div class="flex  pdl30 pdr30 pdt_20">
                <el-button type="primary" class="width_110" icon="el-icon-circle-plus-outline"
                    style=" background: #03CABE;border: none;" @click="add">新增
                </el-button>
                <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button>
            </div>
            <div class="pdl30 pdr30 hei80V">
                <el-table :data="queryList" style="width: 100%" class=" mg_top_20" height="100%"
                @selection-change="handleSelectionChange" @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                     <el-table-column type="index" label="序号" width="100">
                    </el-table-column>
                    <!-- <el-table-column prop="ID" label="id">
                    </el-table-column> -->
                    <!-- <el-table-column prop="projectID" label="项目编号">
                    </el-table-column> -->
                    <el-table-column prop="sparepartUnit" label="备件单位">
                    </el-table-column>
                    <el-table-column prop="remarks" label="描述">
                    </el-table-column>
                    <el-table-column label="操作" width="220">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="gotoModify(scope.row)">
                                修改
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="delTableData(scope.row.ID)">
                                删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <el-dialog title="新增" :visible.sync="dialogFormVisible" top="20vh" width="30%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">新增</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="addform">
                    <!-- <el-form-item label="用户编号" :label-width="formLabelWidth">
                        <el-input v-model="addform.userID" autocomplete="off" class=""></el-input>
                    </el-form-item> -->
                    <el-form-item label="备件单位" :label-width="formLabelWidth">
                        <el-input v-model="addform.sparepartUnit" autocomplete="off" class=""></el-input>
                    </el-form-item>
                    <el-form-item label="描述" :label-width="formLabelWidth">
                        <el-input type="textarea"  :rows="3" v-model="addform.remarks" autocomplete="off" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="trueadd">确 定</el-button>
            </div>

            <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div> -->
        </el-dialog>

        <!--修改小窗-->
        <el-dialog title="修改" :visible.sync="dialog2FormVisible" top="20vh" width="30%">
            <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">修改</div> -->
            <div class="flex justify_center mg_top_20">
                <el-form :model="updateform">
                    <el-form-item label="备件单位: " :label-width="formLabelWidth">
                        <el-input v-model="updateform.sparepartUnit" @keyup.native="number" autocomplete="off" class="">
                        </el-input>
                    </el-form-item>
                    <!--
                    <el-form-item label="评分等级分类：" :label-width="formLabelWidth">
                        <el-input v-model="form.gradeSettingsCategory" autocomplete="off" class=""></el-input>
                    </el-form-item>
                    -->
                    <el-form-item label="描述：" :label-width="formLabelWidth">
                        <el-input type="textarea"  :rows="3" v-model="updateform.remarks" autocomplete="off" class=""></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="flex justify_center pdb_20 ">
                <el-button @click="dialog2FormVisible = false">取 消</el-button>
                <el-button type="primary" @click="trueModify">确 定</el-button>
            </div>

            <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
            </div> -->
        </el-dialog>

    </div>
</template>
<script>
    import {
        rMISparepartUnitSettingsInsert,
        rMISparepartUnitSettingsQueryLimit,
        rMISparepartUnitSettingsDelete,
        rMISparepartUnitSettingsUpdate
    } from "@/api/api_systemConfig";
    export default {
        data() {
            return {
                value: '',
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1517 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄'
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1516 弄'
                }],
                dialogFormVisible: false,
                dialog2FormVisible: false,
                addform: {
                    userID: '',
                    sparepartUnit: '',
                    remarks: ''
                },
                updateform: {
                    ID: '',
                    sparepartUnit: '',
                    remarks: ''
                },
                queryList: [],
                deleteNum: '',
                formLabelWidth: '120px',
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            };

            this.rMISparepartUnitSettingsQueryLimit();
        },
        methods: {
             //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '备件单位配置表')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },


            //（1）新增
            //点击‘新增’按钮时触发的方法
            add() {
                //将原有数据清空，以备用来新增评分等级
                this.addform = {};
                //打开新增窗口有dialog
                this.dialogFormVisible = true;
            },
            //点击dialog里‘确定’按钮的方法
            trueadd() {
                //调用新增方法
                this.rMISparepartUnitSettingsInsert();
            },
            //新增方法
            async rMISparepartUnitSettingsInsert() {
                //封装参数
                this.addform.userID = this.userID;
                var param = {
                    userID: this.addform.userID,
                    sparepartUnit: this.addform.sparepartUnit,
                    remarks: this.addform.remarks
                }
                console.log(param)
                //调用新增接口
                const res = await rMISparepartUnitSettingsInsert(param);
                console.log(res)
                if (res && res.data && res.data.result) {
                    this.dialogFormVisible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    //调用筛选方法
                    this.rMISparepartUnitSettingsQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //（2）筛选 分页数据
            handleCurrentChange(val) {
                this.currentPage = val;
                this.rMISparepartUnitSettingsQueryLimit();
            },
            async rMISparepartUnitSettingsQueryLimit() {
                var param = {
                    userID: this.userID,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                console.log(param)
                const res = await rMISparepartUnitSettingsQueryLimit(param);
                this.queryList = res.rows;
                this.pageQuantity = res.pageQuantity;
            },
            //(5)修改
            gotoModify(temp) {
                this.updateform.ID = temp.ID;
                this.updateform.sparepartUnit = temp.sparepartUnit;
                this.updateform.remarks = temp.remarks;

                this.dialog2FormVisible = true;
            },
            trueModify() {
                this.rMISparepartUnitSettingsUpdate();
            },
            async rMISparepartUnitSettingsUpdate() {
                var param = {
                    ID: this.updateform.ID,
                    sparepartUnit: this.updateform.sparepartUnit,
                    remarks: this.updateform.remarks
                }

                const res = await rMISparepartUnitSettingsUpdate(param);

                if (res && res.data && res.data.result) {
                    this.dialog2FormVisible = false;
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.rMISparepartUnitSettingsQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            },
            //(4)删除
            delTableData(id) {
                this.deleteNum = id;
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentPage = this.delData.delData(this.queryList.length, this.pageQuantity,this.currentPage)
                    this.rMISparepartUnitSettingsDelete();
                }).catch(() => {

                });
            },
            async rMISparepartUnitSettingsDelete() {
                var param = {
                    ID: this.deleteNum
                }
                console.log(param)

                const res = await rMISparepartUnitSettingsDelete(param);
                console.log(res)

                if (res && res.data && res.data.result) {
                    this.currentPage = 1
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.rMISparepartUnitSettingsQueryLimit();
                } else {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'error'
                    });
                }
            }
        }
    }
</script>
<style scoped>
    .hei80V {
        height: 78%;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form-item {
        margin-bottom: 14px
    }
</style>